<template>
  <div>
    <div class="order-create__row custom-row"
         v-if="ex.express.type === EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id
         && ex.express.orderCountryTypesArray.length > 1 &&
          ex.express.user && isUkrainianByUserData(getUserContactDataFromUser(ex.express.user).country_group) && !getUserAlternativeContact(ex.express.user)"
    >
      <div class="order-create__col custom-col">
        <LargeSwitch
            :activeValue="ex.express.orderCountryTypesArray[0].value"
            :inactiveValue="ex.express.orderCountryTypesArray[1].value"
            :labelActiveValue="ex.express.orderCountryTypesArray[0].label"
            :labelInactiveValue="ex.express.orderCountryTypesArray[1].label"
            :checkedItem="ex.express.orderCountryType"
            :name="'orderCountryTypeSwitch'"
            v-bind:class="{
              'disable-large-switch': ex.express.type === EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id &&
                  ex.express.orderCountryType !== ex.express.orderCountryTypesArray[1].value &&
                  ((!this.isUseAlternativeContact && this.isUkrainian) || this.isUserAlternativeContactUkraine)
            }"
            @change="changeOrderCountryTypeSwitch"
        />
      </div>
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'ex_warehouse',
          'ex_sender',
        ])"></div>


        <LargeSwitch
            v-if="ex.express.type === EXPRESS_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id"
            v-bind:class="{
              'disable-large-switch': isCurrentUserRoleUser &&
              (
                  (!isDHLNoTrn && !hasTransaction(ex.express)) ||
                  (isEdit && !isDHLNoTrn && !hasTransaction(ex.express) && ex.express.sendType === ex.express.sendTypes.warehouse) ||
                  (!isEdit && ex.express.typeTransaction === EXPRESS_FROM_TRANSACTIONS.ORDER.value && ex.express.typeTransactionId === '')
              ) ||
              ex.express.orderCountryType === EXPRESS_DHL_COUNTRY_TYPES.PL ||
              !this.ex.express.canUserSendDHLFromAddress
            }"
            :activeValue="ex.express.sendTypes.warehouse"
            :inactiveValue="ex.express.sendTypes.sender"
            :labelActiveValue="$t('ex_warehouse.localization_value.value')"
            :labelInactiveValue="$t('ex_sender.localization_value.value')"
            :checkedItem="ex.express.sendType"
            :name="'orderCreateSwitch'"
            @change="changeOrderCreateSwitch"
        />

        <LargeSwitch
            v-if="ex.express.type === EXPRESS_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id"
            v-bind:class="{
              'disable-large-switch': !isTNTNoTrn && $store.getters.getIsAdminRights === 'user' && !hasTransaction(ex.express)
            }"
            :activeValue="ex.express.sendTypes.warehouse"
            :inactiveValue="ex.express.sendTypes.sender"
            :labelActiveValue="$t('ex_warehouse.localization_value.value')"
            :labelInactiveValue="$t('ex_sender.localization_value.value')"
            :checkedItem="ex.express.sendType"
            :name="'orderCreateSwitch'"
            @change="changeOrderCreateSwitch"
        />

        <LargeSwitch
            v-if="ex.express.type === EXPRESS_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id"
            v-bind:class="{
              'disable-large-switch': checkDisabledSenderFedex
            }"
            :activeValue="ex.express.sendTypes.warehouse"
            :inactiveValue="ex.express.sendTypes.sender"
            :labelActiveValue="$t('ex_warehouse.localization_value.value')"
            :labelInactiveValue="$t('ex_sender.localization_value.value')"
            :checkedItem="ex.express.sendType"
            :name="'orderCreateSwitch'"
            @change="changeOrderCreateSwitch"
        />

<!--            v-bind:class="{-->
<!--              'disable-large-switch': checkDisabledSenderFedex-->
<!--            }"-->
        <LargeSwitch
            v-if="ex.express.type === EXPRESS_DELIVERY_SERVICES.NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id"
            v-bind:class="{
              'disable-large-switch': true
            }"
            :activeValue="ex.express.sendTypes.warehouse"
            :inactiveValue="ex.express.sendTypes.sender"
            :labelActiveValue="$t('ex_warehouse.localization_value.value')"
            :labelInactiveValue="$t('ex_sender.localization_value.value')"
            :checkedItem="ex.express.sendType"
            :name="'orderCreateSwitch'"
            @change="changeOrderCreateSwitch"
        />
      </div>
    </div>

    <div class="order-create__section-sub" v-if="ex.express.sendType === ex.express.sendTypes.sender">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33 custom-col--lg-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'express_ContactName',
        ])"></div>
          <DefaultInput
              :label="$t('express_ContactName.localization_value.value')"
              v-bind:class="{'ui-no-valid': ex.validation.senderContactName}"
              :errorTxt="serverError ? ex.validationTxt.senderContactName : $t(`${ex.validationTranslate.senderContactName}.localization_value.value`)"
              :error="ex.validation.senderContactName"
              v-model="ex.express.senderContactName"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--lg-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'common_phone',
        ])"></div>
          <DefaultInput
              :label="$t('common_phone.localization_value.value')"
              :type="'tel'"
              v-bind:class="{'ui-no-valid': ex.validation.senderPhone}"
              :errorTxt="serverError ? ex.validationTxt.senderPhone : $t(`${ex.validationTranslate.senderPhone}.localization_value.value`)"
              :error="ex.validation.senderPhone"
              v-model="ex.express.senderPhone"
          />
        </div>
      </div>

      <div class="order-create__row custom-row" v-if="AUTHORIZED = getUserContacts">
        <div class="order-create__col custom-col"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'common_address',
          'common_city',
          'common_region',
          'common_country',
          'common_zipCode',
        ])"></div>
          <div class="order-create__table block-table">
            <div class="block-table__row">
              <div class="block-table__col block-table__col--20">
                <div class="block-table__label">
                  {{ $t('common_address.localization_value.value') }}
                </div>
                <div class="block-table__content">
                  <template v-if="Object.keys(ex.express.user).length > 0">
                    {{ getUserContactsBySelectedUser.address }}
                  </template>
                  <template v-else>
                    {{ AUTHORIZED.address }}
                  </template>
                </div>
              </div>
              <div class="block-table__col block-table__col--20">
                <div class="block-table__label">
                  {{ $t('common_city.localization_value.value') }}
                </div>
                <div class="block-table__content">
                  <template v-if="Object.keys(ex.express.user).length > 0">
                    {{ getUserContactsBySelectedUser.city }}
                  </template>
                  <template v-else>
                    {{ AUTHORIZED.city }}
                  </template>
                </div>
              </div>
              <div class="block-table__col block-table__col--20">
                <div class="block-table__label">
                  {{ $t('common_region.localization_value.value') }}
                </div>
                <div class="block-table__content">
                  <template v-if="Object.keys(ex.express.user).length > 0">
                    {{ getUserContactsBySelectedUser.region.name }}
                  </template>
                  <template v-else>
                    {{ AUTHORIZED.region.name }}
                  </template>
                </div>
              </div>
              <div class="block-table__col block-table__col--20">
                <div class="block-table__label">
                  {{ $t('common_country.localization_value.value') }}
                </div>
                <div class="block-table__content">
                  <template v-if="Object.keys(ex.express.user).length > 0">
                    {{ getUserContactsBySelectedUser.country.name }}
                  </template>
                  <template v-else>
                    {{ AUTHORIZED.country.name }}
                  </template>
                </div>
              </div>
              <div class="block-table__col block-table__col--20">
                <div class="block-table__label">
                  {{ $t('common_zipCode.localization_value.value') }}
                </div>
                <div class="block-table__content">
                  <template v-if="Object.keys(ex.express.user).length > 0">
                    {{ getUserContactsBySelectedUser.zip }}
                  </template>
                  <template v-else>
                    {{ AUTHORIZED.zip }}
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'express_HowChangeInformation',
          'express_ChangeYourAddress',
        ])"></div>
          <WarningStatic>
            <template slot="title">
              {{ $t('express_HowChangeInformation.localization_value.value') }}
            </template>
            <template slot="text">
              {{ $t('express_ChangeYourAddress.localization_value.value') }}
            </template>
          </WarningStatic>
        </div>
      </div>
    </div>

    <!--    && $store.getters.getWarehouses.length > 0-->
    <div class="order-create__section-sub"
         v-if="ex.express.sendType === ex.express.sendTypes.warehouse ">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33 custom-col--lg-50 custom-col--sm-100"
             v-if="!isUserHasEasyOrder"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'ex_warehouse',
        ])"></div>
          <DefaultSelect
              :options="warehouseOptions"
              :label="$t('ex_warehouse.localization_value.value')"
              :otherValue="'currentTranslation'"
              v-bind:class="{'ui-no-valid': ex.validation.warehouse}"
              :errorTxt="serverError ? ex.validationTxt.warehouse : $t(`${ex.validationTranslate.warehouse}.localization_value.value`)"
              :error="ex.validation.warehouse"
              :selected="ex.express.warehouse"
              @change="changeWarehouse"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--lg-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'express_TrackingNumber',
          'express_TrackingNumberEasy',
        ])"></div>
          <DefaultInput
              :label="isUserHasEasyOrder ? $t('express_TrackingNumberEasy.localization_value.value') : $t('express_TrackingNumber.localization_value.value')"
              v-bind:class="{'ui-no-valid': ex.validation.trackingNumber}"
              :errorTxt="serverError ? ex.validationTxt.trackingNumber : $t(`${ex.validationTranslate.trackingNumber}.localization_value.value`)"
              :error="ex.validation.trackingNumber"
              v-model="ex.express.trackingNumber"
          />
        </div>

        <div class="order-create__col custom-col custom-col--33 custom-col--lg-50 custom-col--sm-100"
             v-if="$store.getters.GET_COMMON_AUTHORIZED && $store.getters.GET_COMMON_AUTHORIZED.user && $store.getters.GET_COMMON_AUTHORIZED.user.email
    && $store.getters.GET_COMMON_AUTHORIZED.user.email === 'admin@expressposhta.com'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
          'express_expressTrack',
        ])"></div>
          <DefaultInput
              :label="$t('express_expressTrack.localization_value.value')"
              v-model="ex.express.expressTrack"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LargeSwitch from "../../../../../../UI/radiobuttons/LargeSwitch/LargeSwitch";
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import WarningStatic from "../../../../../../UI/alerts/WarningStatic/WarningStatic";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {warehousesMixin} from "../../../../../../../mixins/commonMixins/warehousesMixin";
import {expressMixins} from "../../../../../../../mixins/expressMixins/expressMixin";
import {
  // CITY_KIEV,
  // CITY_LVIV,
  // CITY_MINSK,
  // DHLEXPRESS_DELIVERY_SERVICE,
  EXPRESS_DELIVERY_SERVICES,
  EXPRESS_DHL_COUNTRY_TYPES,
  EXPRESS_FROM_TRANSACTIONS, FEDEX_DELIVERY_SERVICE,
} from "../../../../../../../staticData/staticVariables";
import {expressUserContactsMixin} from "../../../../../../../mixins/expressMixins/expressUserContactsMixin";

export default {
  name: "PickupInfo",

  components: {
    LargeSwitch,
    DefaultInput,
    WarningStatic,
    DefaultSelect,
  },

  mixins: [warehousesMixin, expressMixins, expressUserContactsMixin],

  props: {
    ex: Object,
    serverError: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getUser() {
      return this.ex.express.user
    },

    user: function () {
      return this.$store.getters.getUserProfile
    },

    userExpress: function () {
      return this.ex.express.userId
    },


  },

  watch: {
    getUser() {
      this.getWarehouses()
    },

    user: function (newCount) {
      if (this._.has(newCount, 'id')) {
        this.getWarehouses()
      }
    },

    userExpress: function () {
      this.getWarehouses()
    },

    'ex.express.orderCountryType'() {
      if (this.isEdit && this.ex.express.userId !== '') {
        this.getWarehouses(true)
        return
      }

      if (Object.keys(this.$store.getters.getUserProfile).length > 0) {
        this.changeWarehouse(null)
        this.getWarehouses()
      }
    }
  },

  data() {
    return {
      checkedOrderCreateSwitch: 'warehouse',
      warehouseOptions: [],

      EXPRESS_DELIVERY_SERVICES: EXPRESS_DELIVERY_SERVICES,
      EXPRESS_DHL_COUNTRY_TYPES: EXPRESS_DHL_COUNTRY_TYPES,
      EXPRESS_FROM_TRANSACTIONS: EXPRESS_FROM_TRANSACTIONS,
    }
  },

  mounted() {
    if (this.isEdit && this.ex.express.userId !== '') {
      this.getWarehouses()
      return
    }

    if (Object.keys(this.$store.getters.getUserProfile).length > 0) {
      this.getWarehouses()
    }
  },

  methods: {
    changeOrderCreateSwitch(data) {
      this.ex.express.sendType = data
    },

    changeOrderCountryTypeSwitch(data) {
      this.ex.express.orderCountryType = data
      //if from pl or from ua and no transaction - only warehouse
      if (this.ex.express.orderCountryType === this.EXPRESS_DHL_COUNTRY_TYPES.PL || (this.ex.express.orderCountryType === this.EXPRESS_DHL_COUNTRY_TYPES.UA &&
          !this.isDHLNoTrn && !this.hasTransaction(this.ex.express))) {
        this.ex.express.sendType = this.ex.express.sendTypes.warehouse
      }
    },

    changeWarehouse(val) {
      this.ex.express.warehouse = val

      if(val && this.ex.express.type === FEDEX_DELIVERY_SERVICE.id && this.ex.express.externalImportOrderCountry?.id) {
        this.$store.dispatch('fetchCountries', '?filter[byWarehouseId]=' + this.ex.express.warehouse?.id).then(() => {
          let countries = this._.toArray(this.$store.getters.getCountries)
          if(!this._.find(countries, {id: this.ex.express.externalImportOrderCountry?.id})) {
            this.ex.express.delivery.delivery.country = ''
          } else {
            this.ex.express.delivery.delivery.country = this.ex.express.externalImportOrderCountry
          }
        })
      } else {
        this.ex.express.delivery.delivery.country = ''
      }
    },

    getWarehouses(orderCountryTypeChanged = false) {
      console.log(2222);
      console.log(this.ex.getExpressType());
      this.getWarehousesFromCountryGroup().then(response => {
        this.warehouseOptions = response

        let typeReturn = this.manipulateWarehouses(this.isEdit)
        console.log(typeReturn);

        if (this.isEdit && orderCountryTypeChanged) {
          let findOption = this.warehouseOptions.find(item => {
            return item.id === this.ex.express.warehouse?.id
          })
          if (!findOption) this.changeWarehouse(null)
          return
        }

        if (this.isEdit && !this.ex.express.isCopy) return

        if (typeReturn) return

        this.checkActiveOneWarehouse(response)
      })
    },

    checkActiveOneWarehouse(response) {
      if (response.length === 1) {
        this.changeWarehouse(response[0])
      }
    }
  }
}
</script>

<style scoped>

</style>
